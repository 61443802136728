import Draft from '@/calendesk/models/DTO/Response/Draft'
import api from '@/lib/calendesk-js-library/api/api'
import { debounce } from 'ts-debounce'
import Section from '@/calendesk/models/DTO/Response/Section'
import Page from '@/calendesk/models/DTO/Response/Page'
import { ChangeStep, ChangeStepType } from '@/calendesk/models/ChangeStep'
import store from '@/store/index'
import { RestoredStep, RestoreType } from '@/calendesk/models/RestoredStep'
import SectionImage from '../models/DTO/Response/SectionImage'
import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import { BuilderState } from '@/store/modules/builder/types'
import DraftWithPage from '@/calendesk/models/DraftWithPage'
import { plainToClass } from 'class-transformer'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'
import { SectionImagePayload } from '@/calendesk/models/SectionImagePayload'
import WebsiteStyleModel from '@/calendesk/models/WebsiteStyleModel'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

declare global {
  interface Window { DraftController: any }
}

export const DRAFT_VERSION = 60

export default class DraftStateController {
  private static instance: DraftStateController;

  public updateDraftDebounce = debounce(this.updateDraft, 1000)
  public updateSectionDebounce = debounce(this.updateSection, 1000)
  public updatePageDebounce = debounce(this.updatePage, 1000)

  private currentStepIndex = 0

  public static getInstance (): DraftStateController {
    if (!DraftStateController.instance) {
      DraftStateController.instance = new DraftStateController()
      window.DraftController = DraftStateController.instance
    }

    return DraftStateController.instance
  }

  public updateDraft (draft: Draft, changeStep: ChangeStep | null = null): Promise<void> {
    if (changeStep) {
      this.addChangeStep(changeStep)
    }

    store.commit('builder/SET_WEBSITE_STYLE_MODEL', WebsiteStyleModel.getModelForDraft(draft))

    return api.updateWb2Draft(draft.id, {
      configuration: draft.configuration
    })
  }

  public updateSectionsIfNeeded (draft: Draft) {
    draft.pages.forEach((page: Page) => {
      page.sections?.forEach((section: Section) => {
        let updateSection = false

        if (section.configuration.component_id === SectionsSectionType.CALENDAR_V2) {
          if (typeof section.configuration.wb_expand_collapse_service_panels__checkbox__ === 'undefined') {
            updateSection = true
            section.configuration.wb_expand_collapse_service_panels__checkbox__ = false
          }
        }

        if (section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_1 ||
          section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_2 ||
          section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_3 ||
          section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_4 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_1 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_2 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_3 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_4 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_5 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_6) {
          if (typeof section.configuration.wb_initial_location_select__checkbox__ === 'undefined') {
            updateSection = true
            section.configuration.wb_initial_location_select__checkbox__ = true
          }
        }

        if (section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_2 ||
          section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_3 ||
          section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_4 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_1 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_2 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_3 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_4 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_5 ||
          section.configuration.component_id === SectionsSectionType.BOOKING_6) {
          if (typeof section.configuration.wb_show_booked_slots__checkbox__ === 'undefined') {
            updateSection = true
            section.configuration.wb_show_booked_slots__checkbox__ = false
          }
        }

        if (section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_1 ||
          section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_2 ||
          section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_3 ||
          section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_4) {
          if (typeof section.configuration.wb_init_calendar_title__html_text__ === 'undefined') {
            updateSection = true
            section.configuration.wb_init_calendar_title__html_text__ = `<h6 class="text-h6" style="text-align: center">${trans('wb_init_calendar_title_example')}</h6>`
          }

          if (typeof section.configuration.wb_initial_service_selection__checkbox__ === 'undefined') {
            updateSection = true
            section.configuration.wb_initial_service_selection__checkbox__ = false
          }

          if (section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_2 ||
            section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_3 ||
            section.configuration.component_id === SectionsSectionType.FLEXIBLE_BOOKING_4) {
            if (typeof section.configuration.wb_calendar_min_date__date__ === 'undefined') {
              updateSection = true
              section.configuration.wb_calendar_min_date__date__ = null
            }

            if (typeof section.configuration.wb_calendar_max_date__date__ === 'undefined') {
              updateSection = true
              section.configuration.wb_calendar_max_date__date__ = null
            }

            if (typeof section.configuration.wb_number_of_days__number__ !== 'undefined') {
              updateSection = true
              delete section.configuration.wb_number_of_days__number__
            }
          }
        }

        if (updateSection) {
          DraftStateController.getInstance().updateSection(draft, section)
        }
      })
    })
  }

  public updateSection (draft: Draft, section: Section, changeStep: ChangeStep | null = null): Promise<void> {
    if (changeStep) {
      this.addChangeStep(changeStep)
    }

    return api.updateWb2DraftSection(draft.id, section.id, {
      configuration: section.configuration
    })
  }

  public updatePage (draft: Draft, page: Page, changeStep: ChangeStep | null = null): Promise<void> {
    if (changeStep) {
      this.addChangeStep(changeStep)
    }
    return api.updateWb2DraftPage(draft.id, page.id, {
      type: page.type,
      name: page.name,
      route: page.route,
      action: page.action,
      auth_only: page.authOnly,
      configuration: page.configuration
    })
  }

  public createPage (draft: Draft, page: Page, changeStep: ChangeStep | null = null): Promise<Record<string, any>> {
    return new Promise((resolve, reject) => {
      api.createWb2DraftPage(draft.id, {
        type: page.type,
        name: page.name,
        route: page.route,
        action: page.action,
        auth_only: page.authOnly,
        configuration: page.configuration
      }).then((response: any) => {
        if (changeStep) {
          changeStep.newObject = plainToClass(Page, response.data)
          changeStep.oldObject = plainToClass(Page, response.data)
          this.addChangeStep(changeStep)
        }
        resolve(response)
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  public removeSection (draft: Draft, section: Section): Promise<Record<string, any>> {
    store.dispatch('builder/clearAllSteps')
    store.dispatch('sidebar/closeSidebar')
    return api.removeWb2DraftSection(draft.id, section.id)
  }

  public removePage (draft: Draft, page: Page): Promise<Record<string, any>> {
    store.dispatch('builder/clearAllSteps')
    return api.removeWb2DraftPage(draft.id, page.id)
  }

  public createSection (draft: Draft, section: Section, changeStep: ChangeStep | null = null): Promise<Record<string, any>> {
    return new Promise((resolve, reject) => {
      api.createWb2DraftSection(draft.id, {
        type: section.type,
        page_id: section.pageId,
        configuration: section.configuration,
        images: section.images
      }).then((response: any) => {
        if (changeStep) {
          changeStep.newObject = plainToClass(Section, response.data as Section)
          changeStep.oldObject = plainToClass(Section, response.data as Section)
          this.addChangeStep(changeStep)
        }
        resolve(response)
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  public updateImage (draft: Draft, section: Section, payload: SectionImagePayload): Promise<Record<string, any>> {
    return api.updateWb2DraftSectionImage(draft.id, section.id, {
      user_image_id: payload.selectedGalleryImage.defaultImage.userImageId,
      slug: payload.sectionImage.slug,
      type: payload.sectionImage.type
    })
  }

  public removeImage (draft: Draft, section: Section, image: SectionImage): Promise<Record<string, any>> {
    return api.removeWb2DraftSectionImage(draft.id, section.id, image.slug)
  }

  private increaseStepIndex (): void {
    this.currentStepIndex++
    store.commit('builder/SET_CURRENT_STEP_INDEX', this.currentStepIndex)
  }

  private decreaseStepIndex (): void {
    this.currentStepIndex--
    store.commit('builder/SET_CURRENT_STEP_INDEX', this.currentStepIndex)
  }

  private addChangeStep (changeStep: ChangeStep) {
    this.increaseStepIndex()
    store.dispatch('builder/addChangeStep', changeStep)
  }

  public restoreLastStep (): Promise<RestoredStep> {
    this.decreaseStepIndex()
    return this.restoreStep(this.currentStepIndex, RestoreType.UNDO)
  }

  public restoreNextStep (): Promise<RestoredStep> {
    const response = this.restoreStep(this.currentStepIndex, RestoreType.REDO)
    this.increaseStepIndex()
    return response
  }

  public restoreStep (index: number, type: RestoreType): Promise<RestoredStep> {
    return new Promise((resolve, reject) => {
      if (store.state.builder.steps[index]) {
        const restoreToChangeStep: ChangeStep = store.state.builder.steps[index]
        const draft = type === RestoreType.UNDO ? restoreToChangeStep.oldDraft : restoreToChangeStep.newDraft
        const object = type === RestoreType.UNDO ? restoreToChangeStep.oldObject : restoreToChangeStep.newObject

        if (restoreToChangeStep.type === ChangeStepType.UPDATE_DRAFT) {
          this.updateDraft(draft as Draft).then(() => {
            resolve(new RestoredStep(cloneClassObject(restoreToChangeStep), type))
          })
        } else if (restoreToChangeStep.type === ChangeStepType.UPDATE_SECTION) {
          this.updateSection(draft as Draft, object as Section).then(() => {
            resolve(new RestoredStep(cloneClassObject(restoreToChangeStep), type))
          })
        } else if (restoreToChangeStep.type === ChangeStepType.ADD_SECTION && type === RestoreType.UNDO) {
          this.removeSection(draft, object as Section).then(() => {
            store.dispatch('builder/clearFutureSteps')
            store.dispatch('sidebar/closeSidebar')

            resolve(new RestoredStep(cloneClassObject(restoreToChangeStep), type))
          })
        } else if (restoreToChangeStep.type === ChangeStepType.ADD_PAGE && type === RestoreType.UNDO) {
          this.removePage(draft, object as Page).then(() => {
            store.dispatch('builder/clearFutureSteps')
            store.dispatch('sidebar/closeSidebar')

            resolve(new RestoredStep(cloneClassObject(restoreToChangeStep), type))
          })
        } else {
          reject(new Error('Can not restore - step to restore unknown'))
        }
      } else {
        reject(new Error('Can not restore - no steps'))
      }
    })
  }

  public getCurrentPageElements (state: BuilderState, getters: any, includeEmptySection = false): DraftWithPage {
    const draft: Draft = cloneClassObject(state.draft) as Draft
    const currentPage: Page | undefined = draft.pages.find((page: Page) => page.id === getters.getCurrentPage.id)

    if (!includeEmptySection) {
      if (currentPage !== undefined) {
        if (!currentPage.sections) {
          currentPage.sections = []
        } else {
          currentPage.sections = currentPage.sections.filter((_: Section) => _.type !== SectionType.EMPTY)
        }
      }
    }

    return { draft, page: currentPage }
  }

  public checkIfPlainDraftIsMissingAnyConfiguration (draft: any) {
    if (typeof draft.configuration.wb_hide_booking_employees__checkbox__ === 'undefined') {
      draft.configuration.wb_hide_booking_employees__checkbox__ = false
    }

    if (typeof draft.configuration.wb_og_image_url__text__ === 'undefined') {
      draft.configuration.wb_og_image_url__text__ = 'https://media.calendesk.com/demo/og-en-1.jpg'
    }

    if (typeof draft.configuration.wb_favicon_url__text__ === 'undefined') {
      draft.configuration.wb_favicon_url__text__ = null
    }

    if (typeof draft.configuration.wb_hide_signup__checkbox__ === 'undefined') {
      draft.configuration.wb_hide_signup__checkbox__ = false
    }

    if (typeof draft.configuration.wb_hide_login__checkbox__ === 'undefined') {
      draft.configuration.wb_hide_login__checkbox__ = !draft.configuration.wb_login_enabled__checkbox__
      delete draft.configuration.wb_login_enabled__checkbox__
    }

    if (typeof draft.configuration.wb_hide_header__checkbox__ === 'undefined') {
      draft.configuration.wb_hide_header__checkbox__ = false
    }

    if (typeof draft.configuration.wb_hide_footer__checkbox__ === 'undefined') {
      draft.configuration.wb_hide_footer__checkbox__ = false
    }

    if (typeof draft.configuration.wb_booking_menu_hidden__checkbox__ !== 'undefined') {
      delete draft.configuration.wb_booking_menu_hidden__checkbox__
    }

    if (typeof draft.configuration.wb_enable_discounts__checkbox__ === 'undefined') {
      draft.configuration.wb_enable_discounts__checkbox__ = false
    }

    if (draft.configuration.signup_modal_configuration &&
      typeof draft.configuration.signup_modal_configuration.wb_show_billing_data__checkbox__ === 'undefined') {
      draft.configuration.signup_modal_configuration.wb_show_billing_data__checkbox__ = false
    }

    if (typeof draft.configuration.wb_primary_language__select__ === 'undefined') {
      draft.configuration.wb_primary_language__select__ = {
        type: BuilderSelectType.LANGUAGE,
        value: store.getters['setup/getAppConfiguration'].language
      }
    }

    if (typeof draft.configuration.wb_newsletter_success_url__url__ === 'undefined') {
      draft.configuration.wb_newsletter_success_url__url__ = null
    }

    if (typeof draft.configuration.wb_hide_time_zone__checkbox__ === 'undefined') {
      draft.configuration.wb_hide_time_zone__checkbox__ = false
    }

    if (typeof draft.configuration.wb_custom_code__long_text__ === 'undefined') {
      draft.configuration.wb_custom_code__long_text__ = null
    }

    if (typeof draft.configuration.wb_hide_booking_time_to__checkbox__ === 'undefined') {
      draft.configuration.wb_hide_booking_time_to__checkbox__ = false
    }

    if (typeof draft.configuration.wb_account_activation_success_url__url__ === 'undefined') {
      draft.configuration.wb_account_activation_success_url__url__ = null
    }

    return draft
  }
}
