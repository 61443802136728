import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { BookingDynamicFormTypes } from '@/calendesk/models/BookingDynamicFormTypes'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'
import { RoundType } from '@/calendesk/models/RoundType'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.CALENDAR_V2,
      wb_position: 0,
      present_height: 1600,
      wb_height__style_height__: 600,
      wb_min_height: 600,
      wb_select_locations_title_label__html_text__: `<h6 class="text-h6" style="text-align: center">${trans('wb_select_locations_title_example_1')}</h6><p style="text-align: center">${trans('wb_select_locations_title_example_2')} 👇</p>`,
      wb_select_services_title_label__html_text__: `<h6 class="text-h6" style="text-align: center">${trans('wb_dummy_text_select_service')} 👇</h6>`,
      wb_select_employees_title_label__html_text__: `<h6 class="text-h6" style="text-align: center">${trans('wb_dummy_text_select_employee')} 👇</h6>`,
      wb_book_now_label__text__: trans('wb_dummy_text_book_now'),
      wb_summary_headline__text__: trans('wb_dummy_text_summary_headline'),
      wb_summary_text__long_text__: trans('wb_default_booking_created_summary'),
      wb_calendar_min_date__date__: null,
      wb_calendar_max_date__date__: null,
      wb_expand_collapse_service_panels__checkbox__: true,
      wb_show_booked_slots__checkbox__: true,
      wb_show_expand_collapse_button__checkbox__: true,
      wb_only_services_with_subscriptions__checkbox__: false,
      wb_any_employee_enabled__checkbox__: true,
      wb_use_booking_cart__checkbox__: true,
      wb_show_name_surname__checkbox__: true,
      wb_show_phone_number__checkbox__: true,
      wb_require_phone_number__checkbox__: true,
      wb_show_billing_data__checkbox__: true,
      wb_require_billing_data__checkbox__: false,
      wb_service_list__services__: [],
      wb_view_rounded__select__: {
        type: BuilderSelectType.VIEW_ROUNDED,
        value: RoundType.MD
      },
      wb_booking_dynamic_form__dynamic_form__: [
        {
          type: BookingDynamicFormTypes.TEXT_AREA,
          label: trans('wb_booking_note'),
          required: false,
          equal: null
        }
      ]
    },
    images: []
  }
}
